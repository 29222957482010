import React, { useContext } from "react"
import styled from "styled-components"

import { Link } from "gatsby"

import SiteContext from "../context/SiteContext"

const Container = styled.div`
  // position: fixed;
  position: absolute;
  width: 10rem;
  top: 0;
  left: 0;
  z-index: 999;
  margin-top: 2.7rem;
  margin-left: 1.5rem;

  .menu-open {
    fill: black !important;
    transition-duration: 0.7s;
  }

  svg {
    height: 100%;
    width: 50%;
    transition-duration: 0.7s;
    fill: ${props => (props.textColor ? props.textColor : "white")};
  }

  @media (min-width: 767px) {
    width: auto;
    margin-left: 3.625rem;
    margin-top: 3.8rem;

    svg {
      width: 40%;
    }
  }
`

export default props => {
  let context = useContext(SiteContext)

  return (
    <Container textColor={context.color}>
      <Link to="/">
		<svg className="site-logo" viewBox="0 0 788.1 210.9"><path class="cls-1" d="M82.92,42.08V130h137.7V42.38h29.7V253h-29.7v-98.7h-138v98.1H53.22V42.08Z" transform="translate(-53.22 -42.08)"/><path class="cls-1" d="M275.82,252.68v-210h94.8c7.5,0,14.7,0,22.2.3,29.7,1.2,43.8,9,52.8,20.4a54,54,0,0,1,10.8,32.4c0,22.5-13.5,34.5-20.1,39.6-5.4,3.6-11.1,6-17.1,8.7,9.6,2.7,15,5.1,18.9,7.2,10.5,5.7,24.9,17.7,24.9,45.6,0,13.2-3.6,24.3-12,34.2-19.2,22.5-49.5,22.2-77.1,21.6Zm29.1-97.2v73.2h65.4c27.6-.3,39.6-.3,51-9.6,9-6.9,11.7-15.9,11.7-27.3,0-4.5-.3-15.6-8.7-24.3-9.6-9.9-22.8-12-50.1-12Zm78-23.1c16.8,0,30,.6,39.6-13.8a32.89,32.89,0,0,0,6-18.9,28.13,28.13,0,0,0-14.4-25.2c-8.4-4.8-15.6-5.4-33.6-5.7-6-.3-12-.3-18.3-.6h-57v64.2Z" transform="translate(-53.22 -42.08)"/><path d="M841.32,43v24.3H715.92v63.9H835v23.7H715.62v97.8h-29.7V43Z" transform="translate(-53.22 -42.08)"/><path class="cls-2" d="M559.92,232v-75.3h-74.7v-24h74.7V58h24v74.7h75.6v24h-75.6V232Z" transform="translate(-53.22 -42.08)"/></svg>		  
      </Link>
    </Container>
  )
}
