import React from "react"

export const defaultSiteContext = {
  color: "white",
  changeColor: () => {},
}

const SiteContext = React.createContext(defaultSiteContext)

export default SiteContext
