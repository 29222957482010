import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"

import gsap, { Power3 } from "gsap"

import { Link } from "gatsby"

import SiteContext from "../context/SiteContext"

import SocialOne from "../images/socials/Instagram Icon.svg"
import SocialTwo from "../images/socials/Facebook Icon.svg"
import SocialThree from "../images/socials/Twitter Icon.svg"
import SocialFour from "../images/socials/Youtube Icon.svg"
import SocialFive from "../images/socials/Spotify Icon.svg"
import SocialSix from "../images/socials/Band Camp Icon.svg"
import SocialSeven from "../images/socials/Apple_Music_Icon_wht.svg"

const Menu = styled.div`
  .menu-toggle {
    // position: fixed;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    height: 2.5rem;
    width: 2rem;
    margin-top: 2rem;
    margin-right: 1.5rem;
    cursor: pointer;

    .menu-open {
      background-color: #272727 !important;
    }

    div {
      position: absolute;
      height: 0.125rem;
      width: 100%;
      background-color: ${props =>
        props.textColor ? props.textColor : "white"};
    }

    @media (min-width: 767px) {
      div {
        width: 100%;
      }
    }
  }

  @media (min-width: 767px) {
    .menu-toggle {
      width: 2.5rem;
      margin-top: 3.8rem;
      margin-right: 3.625rem;
    }
  }

  .top-line,
  .bottom-line {
    transition-duration: 0.3s;
  }

  .top-line {
    top: 35%;
  }

  .bottom-line {
    top: 65%;
  }

  .to-center {
    top: 50%;
  }

  .menu-toggle--open .top-line {
    transform: translate(-50%, -50%) rotate(45deg);
    top: 50%;
    left: 50%;
  }

  .menu-toggle--open .bottom-line {
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 50%;
    left: 50%;
  }

  .menu-toggle--closed .top-line {
    transform: translate(-50%, -50%) rotate(0deg);
    left: 50%;
  }

  .menu-toggle--closed .bottom-line {
    transform: translate(-50%, -50%) rotate(0deg);
    left: 50%;
  }
`

const Container = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
  height: 100%;
  background-color: #d9d8d5;
  z-index: 998;
  box-sizing: border-box;
  overflow: hidden;

  padding: 0 1.5rem;

  transition: background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);
`

const ToggleMenu = styled.div`
  @media(min-width: 992px) {
    display: none;
  }
`

const MenuList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MenuItem = styled(Link)`
  color: #272727;
  text-decoration: none;
  width: fit-content;

  h3 {
    transition: opacity 0.7s;
    font-weight: 300 !important;
    //text-transform: uppercase;
  }

  :hover h3 {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  @media(max-width: 992px) {
    margin-top: 10px;
  }
`

const MenuItemExt = styled.a`
  color: #272727;
  text-decoration: none;
  width: fit-content;

  h3 {
    transition: opacity 0.7s;
    font-weight: 300 !important;
    text-align: center;
  }

  :hover h3 {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 1.5rem 0;

  div {
    transition: opacity 0.7s;
  }

  a:hover div {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  img {
    height: 25px !important;
    width: auto;
  }

  > a {
    opacity: 0;
  }

  > div {
    height: 1.5rem;
    width: 1.5rem;
    padding-left: 0;
  }

  @media (min-width: 767px) {
    margin: 0 0 3.5rem 0;
  }

  @media (min-width: 992px) {
    justify-content: center;

    > a {
      margin: 0 1.25rem;
    }

    > div {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  //   > a:hover {
  //     opacity: 0.5;
  //     transition: opacity 0.3s;
  //   }
`

const Links = styled.div`
  text-transform: uppercase;
  margin-top: 2rem;

  h3 {
    letter-spacing: 1rem;
    text-indent: 1rem;
    font-size: 1rem;
  }

  @media (min-width: 576px) {
    h3 {
      font-size: 1.5rem;
    }
  }
`

const LinksRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 576px) {
    flex-direction: row;
  }

  a {
    margin: 0 2rem;
  }
`

const DesktopMenu = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    margin-right: 3.625rem;
    margin-top: 4.5rem;
    z-index: 999;

    > a {
      margin-left: 1.5rem;
    }

    @media(max-width: 991px) {
      display: none;
    }
`


export default props => {
  let [menuOpen, setMenuOpen] = useState(false)
  let tlList = gsap.timeline()
  let tlSocials = gsap.timeline()
  let context = useContext(SiteContext)

  useEffect(() => {
    let topLine = document.querySelector(".top-line")
    let bottomLine = document.querySelector(".bottom-line")
    let menuToggle = document.querySelector(".menu-toggle")
    let menu = document.querySelector(".menu")
    let menuSocials = document.querySelector(".menu-socials")
    let menuList = document.querySelector(".menu-list")

    let siteLogo = document.querySelector(".site-logo")

    if (menuOpen) {
      //Set logo and menu colors to white;

      topLine.classList.add("menu-open")
      bottomLine.classList.add("menu-open")
      siteLogo.classList.add("menu-open")

      topLine.classList.add("to-center")
      bottomLine.classList.add("to-center")

      menu.classList.remove("menu--closed")
      menu.classList.add("menu--open")

      tlList.staggerTo(
        menuList.children,
        3,
        {
          ease: Power3.easeOut,
          opacity: 1,
          delay: 0,
        },
        0.05
      )

      tlSocials.staggerTo(
        menuSocials.children,
        5,
        {
          ease: Power3.easeOut,
          opacity: 1,
          delay: 0.2,
        },
        0.05
      )

      setTimeout(() => {
        menuToggle.classList.remove("menu-toggle--closed")
        menuToggle.classList.add("menu-toggle--open")
      }, 150)
    } else {
      //Unset logo and menu colors to white;

      topLine.classList.remove("menu-open")
      bottomLine.classList.remove("menu-open")
      siteLogo.classList.remove("menu-open")

      menuToggle.classList.add("menu-toggle--closed")
      menuToggle.classList.remove("menu-toggle--open")

      menu.classList.add("menu--closed")
      menu.classList.remove("menu--open")

      gsap.killTweensOf(menuList.children)
      tlList.staggerTo(menuList.children, 0.5, {
        opacity: 0,
      })

      gsap.killTweensOf(menuSocials.children)
      tlSocials.staggerTo(menuSocials.children, 0.2, {
        opacity: 0,
      })

      setTimeout(() => {
        topLine.classList.remove("to-center")
        bottomLine.classList.remove("to-center")
      }, 150)
    }
  }, [menuOpen])

  let data = useStaticQuery(graphql`
    {
      craft {
        menuElements: entries(section: ["Menu"]) {
          ... on Craft_menu_menu_Entry {
            title
            menu {
              menuItemName
              menuItemUrl
              menuItemActive
            }
          }
        }
        socials: entries(section: ["Socials"]) {
          ... on Craft_socials_socials_Entry {
            socials {
              instagram
              facebook
              twitter
              youtube
              spotify
              bandcamp
              appleMusic
            }
          }
        }
      }
    }
  `)

  let menuElements = data.craft.menuElements[0].menu

  let socials = data.craft.socials[0].socials[0]

  return (
    <Menu textColor={context.color}>
      <ToggleMenu
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        className="menu-toggle menu-toggle--closed"
      >
        <div className="top-line"></div>
        <div className="bottom-line"></div>
      </ToggleMenu>
      <DesktopMenu>
          {menuElements.map(item => {
            if (/^\/(?!\/)/.test(item.menuItemUrl)) {
              return item.menuItemActive ? (
                <MenuItem to={item.menuItemUrl}>
                  <h3>{item.menuItemName}</h3>
                </MenuItem>
              ) : null
            }
          })}
      </DesktopMenu>
      <Container className={"menu menu--closed"}>
        <MenuList
          className="menu-list"
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        >
          {menuElements.map(item => {
            if (/^\/(?!\/)/.test(item.menuItemUrl)) {
              return item.menuItemActive ? (
                <MenuItem to={item.menuItemUrl}>
                  <h3>{item.menuItemName}</h3>
                </MenuItem>
              ) : null
            }
          })}
          {/* <Links>
            <h3>Links</h3>
          </Links>
          <LinksRow>
            {menuElements.map(item => {
              if (/^\/(?!\/)/.test(item.menuItemUrl) === false) {
                return item.menuItemActive ? (
                  <MenuItemExt href={item.menuItemUrl} target="_blank">
                    <h3>{item.menuItemName}</h3>
                  </MenuItemExt>
                ) : null
              }
            })}
          </LinksRow> */}
        </MenuList>
        <Socials className="menu-socials">
          <a href={socials.instagram} target="_blank">
            <div>
              <img src={SocialOne} />
            </div>
          </a>
          {/* <a href={socials.facebook} target="_blank">
            <div>
              <img src={SocialTwo} />
            </div>
          </a>
          <a href={socials.twitter} target="_blank">
            <div>
              <img src={SocialThree} />
            </div>
          </a>
          <a href={socials.youtube} target="_blank">
            <div>
              <img src={SocialFour} />
            </div>
          </a>
          <a href={socials.spotify} target="_blank">
            <div>
              <img src={SocialFive} />
            </div>
          </a>
          <a href={socials.bandcamp} target="_blank">
            <div>
              <img src={SocialSix} />
            </div>
          </a>
          <a href={socials.appleMusic} target="_blank">
            <div>
              <img src={SocialSeven} />
            </div>
          </a> */}
        </Socials>
      </Container>
    </Menu>
  )
}
