/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import SiteContext, { defaultSiteContext } from "../context/SiteContext"

class SiteWrapper extends React.Component {
  state = {
    store: {
      ...defaultSiteContext,
      changeColor: color => {
        this.setState(state => ({
          store: {
            ...state.store,
            color: color,
          },
        }))
      },
    },
  }

  componentDidMount() {}

  render() {
    const { children } = this.props
    return (
      <SiteContext.Provider value={this.state.store}>
        {children}
      </SiteContext.Provider>
    )
  }
}

export default SiteWrapper
