// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-page-js": () => import("./../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cbd-js": () => import("./../src/pages/cbd.js" /* webpackChunkName: "component---src-pages-cbd-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-film-js": () => import("./../src/pages/film.js" /* webpackChunkName: "component---src-pages-film-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-music-js": () => import("./../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-shop-js": () => import("./../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-subscribe-js": () => import("./../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-video-js": () => import("./../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

