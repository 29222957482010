import React, { useContext, useEffect } from "react"
import posed, { PoseGroup } from "react-pose"

import Menu from "./menu"

import Logo from "./logo"

export default props => {
  const { children, location } = props

  // const RoutesContainer = posed.div({
  //   enter: { delay: timeout, delayChildren: timeout },
  // })

  const RoutesContainer = posed.div()

  return (
    <>
      <Menu />
      <Logo />

      {/* <PoseGroup preEnterPose="preEnter" location={location}>
            <RoutesContainer key={location.pathname}> */}
      {/* <Layout {...this.props}>{children}</Layout> */}
      {children}
      {/* </RoutesContainer>
          </PoseGroup> */}
    </>
  )

  // To enable page transitions on mount / initial load,
  // use the prop `animateOnMount={true}` on `PoseGroup`.
}
